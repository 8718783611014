import request from '@/utils/request'

// 查询app用户列表
export function listUser(query) {
  return request({
    url: '/z/user/condition/list',
    method: 'get',
    params: query
  })
}
// app用户上下分
export function vertical(type,money,userId,moneyType) {
  return request({
    url: '/z/user/vertical/split?type='+type+'&money='+money+'&userId='+userId+'&moneyType='+moneyType,
    method: 'get',
  })
}
// 更改上下级
export function subordinate(superiorUserId,subordinateUserId) {
  return request({
    url: '/z/user/reassign/manager/and/subordinate?superiorUserId='+superiorUserId+'&subordinateUserId='+subordinateUserId,
    method: 'get',
  })
}
// app用户重置密码
export function rechargePassword(userId,password,type) {
  return request({
    url: '/z/user/recharge/password?userId='+userId+'&password='+password+'&type='+type,
    method: 'get',
  })
}
//查询用户下级
export function selectSubordinate(query) {
  return request({
    url: '/z/user/select/subordinate',
    method: 'get',
    params: query
  })
}
//产品激活
export function productActivation(data) {
  return request({
    url: '/admin/user/product/activation',
    method: 'post',
    data: data
  })
}

//查询首页统计
export function queryRechargeStatistics() {
  return request({
    url: '/admin/recharge/record/queryRechargeStatistics',
    method: 'get'
  })
}
// 查询app用户详细
export function getUser(id) {
  return request({
    url: '/z/user/' + id,
    method: 'get'
  })
}

// 新增app用户
export function addUser(data) {
  return request({
    url: '/z/user',
    method: 'post',
    data: data
  })
}

// 修改app用户
export function updateUser(data) {
  return request({
    url: '/z/user',
    method: 'put',
    data: data
  })
}

// 删除app用户
export function delUser(id) {
  return request({
    url: '/z/user/' + id,
    method: 'delete'
  })
}
