var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container home" },
    [
      _c(
        "el-card",
        { staticClass: "update-log" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple1" }, [
                  _vm._v(" " + _vm._s(_vm.userInfo.jrzc) + " "),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 今日注册人数 "),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple2" }, [
                  _vm._v(" " + _vm._s(_vm.userInfo.zrzc) + " "),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 昨日注册人数 "),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple3" }, [
                  _vm._v(" " + _vm._s(_vm.userInfo.zzc) + " "),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 总注册人数 "),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple4" }, [
                  _vm._v(" " + _vm._s(_vm.userInfo.jrtx) + " "),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 今日提现人数 "),
                ]),
              ]),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple1" }, [
                  _vm._v(" " + _vm._s(_vm.userInfo.zrtx) + " "),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 昨日提现人数 "),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple2" }, [
                  _vm._v(" " + _vm._s(_vm.userInfo.ztx) + " "),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 总提现金额 "),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple3" }, [
                  _vm._v(" " + _vm._s(_vm.userInfo.zcz) + " "),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 总共充值金额 "),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple1" }, [
                  _vm._v(" " + _vm._s(_vm.userInfo.todayRechargeAmount) + " "),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 今日充值金额 "),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple2" }, [
                  _vm._v(" " + _vm._s(_vm.userInfo.todayRechargeUsers) + " "),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 今日充值人数 "),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple3" }, [
                  _vm._v(
                    " " + _vm._s(_vm.userInfo.yesterdayRechargeAmount) + " "
                  ),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 昨日充值金额 "),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple4" }, [
                  _vm._v(
                    " " + _vm._s(_vm.userInfo.yesterdayRechargeUsers) + " "
                  ),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 昨日充值人数 "),
                ]),
              ]),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple1" }, [
                  _vm._v(
                    " " + _vm._s(_vm.userInfo.thisWeekRechargeAmount) + " "
                  ),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 本周充值金额 "),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple2" }, [
                  _vm._v(
                    " " + _vm._s(_vm.userInfo.thisWeekRechargeUsers) + " "
                  ),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 本周充值人数 "),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple3" }, [
                  _vm._v(
                    " " + _vm._s(_vm.userInfo.lastWeekRechargeAmount) + " "
                  ),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 上周充值金额 "),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple4" }, [
                  _vm._v(
                    " " + _vm._s(_vm.userInfo.lastWeekNumberOfRecharges) + " "
                  ),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 上周充值人数 "),
                ]),
              ]),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple1" }, [
                  _vm._v(
                    " " + _vm._s(_vm.userInfo.thisMonthRechargeAmount) + " "
                  ),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 本月充值金额 "),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple2" }, [
                  _vm._v(
                    " " + _vm._s(_vm.userInfo.thisMonthRechargeUsers) + " "
                  ),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 本月充值人数 "),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple3" }, [
                  _vm._v(
                    " " + _vm._s(_vm.userInfo.lastMonthRechargeAmount) + " "
                  ),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 上月充值金额 "),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content bg-purple4" }, [
                  _vm._v(
                    " " + _vm._s(_vm.userInfo.lastMonthRechargeUsers) + " "
                  ),
                ]),
                _c("div", { staticClass: "grid-content2" }, [
                  _vm._v(" 上月充值人数 "),
                ]),
              ]),
            ],
            1
          ),
          _c("el-divider"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }