<template>
  <div class="app-container home">
    <el-card class="update-log">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="grid-content bg-purple1">
            {{userInfo.jrzc}}
          </div>
          <div class="grid-content2">
            今日注册人数
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple2">
            {{userInfo.zrzc}}
          </div>
          <div class="grid-content2">
            昨日注册人数
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple3">
            {{userInfo.zzc}}
          </div>
          <div class="grid-content2">
            总注册人数
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple4">
            {{userInfo.jrtx}}
          </div>
          <div class="grid-content2">
            今日提现人数
          </div>
        </el-col>
      </el-row>
      <el-divider />

      <el-row :gutter="20">
        <el-col :span="6">
          <div class="grid-content bg-purple1">
            {{userInfo.zrtx}}
          </div>
          <div class="grid-content2">
            昨日提现人数
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple2">
            {{userInfo.ztx}}
          </div>
          <div class="grid-content2">
            总提现金额
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple3">
            {{userInfo.zcz}}
          </div>
          <div class="grid-content2">
            总共充值金额
          </div>
        </el-col>
        <el-col :span="6">
          <!-- <div class="grid-content bg-purple4">
            {{userInfo.yesterdayRechargeUsers}}
          </div>
          <div class="grid-content2">
            今日提现人数
          </div> -->
        </el-col>
      </el-row>

      <el-divider />

      <el-row :gutter="20">
        <el-col :span="6">
          <div class="grid-content bg-purple1">
            {{userInfo.todayRechargeAmount}}
          </div>
          <div class="grid-content2">
            今日充值金额
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple2">
            {{userInfo.todayRechargeUsers}}
          </div>
          <div class="grid-content2">
            今日充值人数
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple3">
            {{userInfo.yesterdayRechargeAmount}}
          </div>
          <div class="grid-content2">
            昨日充值金额
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple4">
            {{userInfo.yesterdayRechargeUsers}}
          </div>
          <div class="grid-content2">
            昨日充值人数
          </div>
        </el-col>
      </el-row>
      <el-divider />
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="grid-content bg-purple1">
            {{userInfo.thisWeekRechargeAmount}}
          </div>
          <div class="grid-content2">
            本周充值金额
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple2">
            {{userInfo.thisWeekRechargeUsers}}
          </div>
          <div class="grid-content2">
            本周充值人数
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple3">
            {{userInfo.lastWeekRechargeAmount}}
          </div>
          <div class="grid-content2">
            上周充值金额
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple4">
            {{userInfo.lastWeekNumberOfRecharges}}
          </div>
          <div class="grid-content2">
            上周充值人数
          </div>
        </el-col>
      </el-row>
      <el-divider />
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="grid-content bg-purple1">
            {{userInfo.thisMonthRechargeAmount}}
          </div>
          <div class="grid-content2">
            本月充值金额
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple2">
            {{userInfo.thisMonthRechargeUsers}}
          </div>
          <div class="grid-content2">
            本月充值人数
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple3">
            {{userInfo.lastMonthRechargeAmount}}
          </div>
          <div class="grid-content2">
            上月充值金额
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple4">
            {{userInfo.lastMonthRechargeUsers}}
          </div>
          <div class="grid-content2">
            上月充值人数
          </div>
        </el-col>
      </el-row>
      <el-divider />
    </el-card>
    
  </div>
</template>

<script>
import {queryRechargeStatistics} from "@/api/work/appUser";
export default {
  name: "Index",
  data() {
    return {
      // 版本号
      version: "3.8.8",
      userInfo:{}
    };
  },
  created(){
    this.queryRechargeStatistics()
  },
  methods: {
    goTarget(href) {
      window.open(href, "_blank");
    },
    queryRechargeStatistics(){
      queryRechargeStatistics().then(res=>{
        this.userInfo=res.data
      })
    }
  }
};
</script>

<style scoped lang="scss">
.el-col {
  border-radius: 4px;
}

.bg-purple1 {
  background: #F56C6C;
}

.bg-purple2 {
  background: #67C23A;
}

.bg-purple3 {
  background: #E6A23C
}

.bg-purple4 {
  background: #37749c;
}

.grid-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  min-height: 56px;
  // color: #fff;
  font-weight: bold;
  font-size: 17px;
}

.grid-content2 {
  font-size: 16px;
  text-align: center;
  font-weight: 400;
}

.home {
  blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #eee;
  }

  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
  }

  .col-item {
    margin-bottom: 20px;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  font-family: "open sans",
  "Helvetica Neue",
  Helvetica,
  Arial,
  sans-serif;
  font-size: 13px;
  color: #676a6c;
  overflow-x: hidden;

  ul {
    list-style-type: none;
  }

  h4 {
    margin-top: 0px;
  }

  h2 {
    margin-top: 10px;
    font-size: 26px;
    font-weight: 100;
  }

  p {
    margin-top: 10px;

    b {
      font-weight: 700;
    }
  }

  .update-log {
    ol {
      display: block;
      list-style-type: decimal;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 40px;
    }
  }
}
</style>
